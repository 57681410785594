import { mapState, mapActions } from 'vuex'
import CryptoJS from 'crypto-js'

export default {
  data () {
    return {
      isRequesting: false,
      giftCodeFieldName: 'gift_code'
    }
  },

  computed: {
    ...mapState('auth', ['profile', 'token']),

    isLoggedIn () {
      return this.token
    }
  },

  methods: {
    ...mapActions('giftCode', ['inputGiftCode']),
    ...mapActions('auth', ['userPoint']),

    async onSubmitGiftCode ({ redirectUrl = 'Index' }) {
      this.isRequesting = true

      const giftCode = this.form.giftCode
      const encryptKey = process.env.VUE_APP_ENCRYPT_KEY || ''
      const giftCodeHash = CryptoJS.SHA256(giftCode + encryptKey).toString(CryptoJS.enc.Hex)

      const inputResult = await this.inputGiftCode({
        gift_code: giftCode,
        hash: giftCodeHash
      })

      const result = inputResult.data
      const { code = null, status = null } = result

      const isCodeError = code ? code !== 200 : false
      const isStatusError = status ? status !== 200 : false

      if (isCodeError || isStatusError) {
        this.isRequesting = false
      } else {
        this.$router.push({
          name: redirectUrl,
          params: {
            id: result.data.id
          }
        })
        return
      }

      if (isCodeError) {
        this.handleSetErrors(result.errors)
      } else if (isStatusError) {
        this.$refs.observer.setErrors({
          [this.giftCodeFieldName]: result.error.message
        })
      }
    },

    handleInputGiftCode (value) {
      const codeUpperCase = value.toUpperCase()
      this.form.giftCode = codeUpperCase
    },

    handleSetErrors (errors) {
      errors.forEach(x => (
        this.$refs.observer.setErrors({
          [x.field]: x.message
        })
      ))
    }
  }
}
